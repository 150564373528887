<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-card elevation="0" class="mb-12">
                    <v-card-title class="justify-center">
                        <v-switch
                            dense
                            inset
                            true-value="ativo"
                            false-value="inativo"
                            hide-details
                            class="pb-2"
                            v-model="form.status"
                            :label="form.status == 'ativo' ? 'Disponível' : 'Indisponível'"
                        />
                        <v-spacer/>
                        {{ form.id ? 'Edição' : 'Cadastro' }} de Combo
                        <v-spacer/>
                    </v-card-title>
                    <v-card-text align="center" justify="center">
                        <v-tabs v-model="tab">
                            <v-tab key="1">GERAl</v-tab>
                            <v-tab key="2">PRODUTOS</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab" class="mt-4">
                            <v-tab-item key="1" class="mt-1">
                                <div>
                                    <v-text-field
                                        ref="nome"
                                        label="Nome do Combo"
                                        v-model="form.descricao"
                                        outlined
                                        dense
                                    />
                                    <v-autocomplete
                                        ref="grupo"
                                        prepend-inner-icon="mdi-magnify"
                                        v-model="form.grupo_id"
                                        :items="grupos"
                                        @keypress.enter="focusNext"
                                        dense
                                        clearable
                                        item-text="descricao"
                                        item-value="id"
                                        label="Grupo (selecione ou cadastre um novo)"
                                        outlined
                                    >
                                        <template #append-item>
                                            <div
                                                style="font-size: 14px; font-weight: bold; padding: 14px; cursor: pointer;"
                                                @click="newGrupo"
                                            >
                                                <v-icon>mdi-plus</v-icon> Cadastrar novo grupo
                                            </div>
                                        </template>
                                        <template #append>
                                            <v-btn
                                                @click="newGrupo"
                                                small
                                                outlined
                                            >
                                                <v-icon>mdi-plus</v-icon> Novo
                                            </v-btn>
                                        </template>
                                    </v-autocomplete>
                                    <v-textarea
                                        clearable
                                        clear-icon="mdi-backspace-outline"
                                        v-model="form.detalhes"
                                        label="Detalhes/Ingredientes"
                                        outlined
                                    />
                                    <v-text-field
                                        label="Preço"
                                        prefix="R$"
                                        v-model="form.valor"
                                        placeholder="0.00"
                                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                        outlined
                                        dense
                                    />
                                    <v-text-field
                                        label="Preço antigo"
                                        prefix="R$"
                                        v-model="form.valor_antigo"
                                        placeholder="0.00"
                                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                        style="text-decoration: line-through;"
                                        outlined
                                        dense
                                    />
                                    <v-text-field
                                        ref="valorMin"
                                        label="Preço à partir de"
                                        prefix="R$"
                                        v-model="form.valor_minimo"
                                        placeholder="0.00"
                                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                        outlined
                                        dense
                                        hide-details
                                    />

                                    <v-switch
                                        v-if="hasFidelidadeModule"
                                        dense
                                        inset
                                        class="py-2"
                                        hide-details
                                        v-model="form.pontos_ativo"
                                        label="Troca por pontos"
                                        outlined
                                    />
                                    <v-text-field
                                        v-if="form.pontos_ativo"
                                        label="Multiplicador de pontos"
                                        v-model="form.pontos_multiplicador"
                                        hide-details
                                        placeholder="0"
                                        type="number"
                                        outlined
                                        dense
                                    />
                                    <div v-if="form.pontos_ativo" style="text-align: left;">
                                        <span class="caption">
                                            Os pontos necessários para o resgate do produto é o valor final do produto incluindo os complementos multiplicado pelo "Multiplicador de pontos" informado acima.
                                            <br>Por exemplo, se o produto custar R$10,00 e o "Multiplicador de pontos" é {{ (form.pontos_multiplicador || 0) }}, serão necessários {{ (form.pontos_multiplicador * 10) || 0 }} pontos para resgatar o produto (10 x {{ form.pontos_multiplicador || 0  }} = {{ (form.pontos_multiplicador * 10) || 0 }}).
                                        </span>
                                    </div>

                                    <div class="mt-6" style="border: solid 1px #999; border-radius: 5px;">
                                        <b>Imagem</b>
                                        <ImageInput
                                            v-model="form.imagem"
                                        />
                                    </div>

                                    <h3 class="mt-8">Disponibilidade</h3>

                                    <v-radio-group
                                        v-model="form.disponibilidade.sempre_disponivel"
                                        row
                                        hide-details
                                        style="margin-top: 5px;"
                                    >
                                        <v-radio
                                            label="Sempre disponível"
                                            :value="true"
                                        />
                                        <v-radio
                                            label="Dias e horários específicos"
                                            :value="false"
                                        />
                                    </v-radio-group>

                                    <div v-if="!form.disponibilidade.sempre_disponivel" class="mt-4 ml-4">
                                        <v-row align="center" v-for="d in form.disponibilidade.programacao" :key="d.diaSemana">
                                            <v-switch
                                                dense
                                                inset
                                                hide-details
                                                class="mr-2"
                                                style="width: 95px;"
                                                v-model="d.aberto"
                                                :label="d.diaSemana.substr(0, 3)"
                                            />
                                            <v-text-field
                                                v-model="d.horario"
                                                hide-details
                                                style="max-width: 105px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                                v-mask="['##:## - ##:##']"
                                                placeholder="00:00 - 00:00"
                                                :disabled="!d.aberto"
                                            />
                                            <v-btn
                                                icon
                                                small
                                                color="primary"
                                                v-if="!d.segundoHorarioAtivo"
                                                @click="toogleSegundoHorario(d.diaSemana)"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                            <v-text-field
                                                v-else
                                                v-model="d.segundoHorario"
                                                hide-details
                                                style="max-width: 130px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                                v-mask="['##:## - ##:##']"
                                                placeholder="00:00 - 00:00"
                                                :disabled="!d.aberto"
                                            >
                                                <template v-slot:append-outer>
                                                    <v-btn icon x-small @click="toogleSegundoHorario(d.diaSemana)">
                                                        <v-icon color="red">mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-text-field>
                                        </v-row>
                                    </div>
                                    
                                    <div class="mb-12"/>
                                </div>
                            </v-tab-item>
                            <v-tab-item key="2">
                                <v-autocomplete
                                    @change="onSelectProduct"
                                    v-model="produto"
                                    :items="produtos"
                                    append-icon="mdi-magnify"
                                    label="Selecione os produtos do combo"
                                    item-text="descricao"
                                    item-value="value"
                                    return-object
                                    single-line
                                    hide-details
                                    outlined
                                />
                                <v-data-table
                                    fixed-header
                                    :headers="headers"
                                    :items="comboProdutos"
                                    sort-by="ordem"
                                    :mobileBreakpoint="0"
                                    height="470"
                                    :disable-pagination="true"
                                    :hide-default-footer="true"
                                    v-sortable-data-table
                                    @sorted="saveOrder"
                                >
                                    <template v-if="hasFiscalModule" v-slot:item.valor="{ item }">
                                        <v-text-field
                                            prefix="R$"
                                            v-model="item.valor"
                                            placeholder="0.00"
                                            v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                            dense
                                            outlined
                                            hide-details
                                        />
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-btn icon color="red" @click="removeProduct(item.produto_id)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
                <v-footer fixed class="pa-4">
                    <v-btn link large text color="warning" to="/combos">Cancelar</v-btn>
                    <v-spacer />
                    <v-btn color="success" large @click="salvar" :loading="loading">
                        <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                    </v-btn>
                </v-footer>
            </v-content>
            <CadastroGrupo
                :visible="grupoVisible"
                :formData="formGrupo"
                @onCadastro="onCadastroGrupo"
                @close="closeGrupo"
            />
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ImageInput from '@/components/ImageInput';
import CadastroGrupo from './Grupo';
import Sortable from 'sortablejs';
import { mapMutations } from 'vuex';
import { clone } from '@/utils/utils';
import { getTimeErrors } from '@/utils/validate';
import { hasModule } from '@/utils/module';

export default {
    name: 'Combo',

    components: {
        BaseContainer,
        CadastroGrupo,
        ImageInput,
    },

    data: () => ({
        form: {
            id: '',
            valor: '',
            valor_antigo: '',
            valor_minimo: '',
            detalhes: '',
            descricao: '',
            produtos: [],
            imagem: [],
            ativo: true,
            status: 'ativo',
            pontos_ativo: false,
            pontos_multiplicador: false,
            disponibilidade: {
                sempre_disponivel: true,
                programacao: [
                    {isoWeekday: 1, diaSemana: 'SEGUNDA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 2, diaSemana: 'TERCA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 3, diaSemana: 'QUARTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 4, diaSemana: 'QUINTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 5, diaSemana: 'SEXTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 6, diaSemana: 'SÁBADO', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 7, diaSemana: 'DOMINGO', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                ],
            },
        },
        search: '',
        loading: false,
        produto: {},
        produtos: [],
        comboProdutos: [],
        formGrupo: {},
        grupos: [],
        grupoVisible: false,
        dialog: false,
        tab: null,
        headers: [
            { text: 'Ordem', value: 'ordem', width: '120', sortable: false },
            { text: 'Descrição', value: 'descricao', sortable: false },
            { text: '', value: 'action', sortable: false, align: 'end' },
        ],
    }),

    computed: {
        hasFiscalModule() {
            return hasModule('FISCAL');
        },

        hasFidelidadeModule() {
            return hasModule('FIDELIDADE');
        },
        
        cssVars() {
            return {
                '--bg-color': '#3874d0',
                '--color': '#fff',
            };
        },
    },

    mounted() {
        if (this.hasFiscalModule) {
            this.headers.splice(2, 0, { text: 'Preço (R$)', value: 'valor',  width: '130', sortable: false });
        }

        const dados = this.$store.getters.getData;

        if (dados?.id) {
            this.form = dados;
            this.$store.commit('setData', {});
        }

        this.comboProdutos = this.form?.produtos_combo || [];

        this.fillProducts();
        this.fillGrupos();

        setTimeout(() => this.$refs.nome.focus(), 100);
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        validate() {
            if (!this.form.descricao) {
                this.notify('Informe o nome do combo', 'warning');
                return;
            }

            if (!this.form.grupo_id) {
                this.notify('Informe o grupo', 'warning');
                return;
            }

            if (this.form.valor === '') {
                this.notify('Informe o preço', 'warning');
                return;
            }

            const timeError = getTimeErrors(this.form.disponibilidade.programacao);

            if (timeError) {
                this.notify(timeError, 'warning');
                return;
            }

            return true;
        },

        salvar() {
            const id = this.form.id;

            this.form.produtos = this.comboProdutos;

            const hasErrors = !this.validate();
            if (hasErrors) {
                return;
            }

            if (id) {
                this.loading = true;
                this.$http.put(`combos/${id}`, this.form).then(() => {
                    this.$router.push('/combos');
                    this.notify('Atualizado com sucesso!');
                    this.setDashboardInitialized(false);
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('combos', this.form).then(() => {
                this.$router.push('/combos');
                this.notify('Salvo com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        fillProducts() {
            this.produtos = [];
            this.$http.get('produtos').then(resp => {
                resp.data.data.forEach(p => {
                    this.produtos.push({
                        ordem: '',
                        produto_id: p.id,
                        descricao: p.descricao,
                        valor: p.valor,
                        value: this.produtos.length
                    });
                });
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        onSelectProduct(produto) {
            const ordem = (this.comboProdutos.length || 0) + 1;
            produto.id = null;
            produto.ordem = ordem;

            this.comboProdutos.push(clone(produto));
            this.produto = {};
        },

        removeProduct(id) {
            console.log(this.comboProdutos, id)
            if (!confirm('Deseja remover o produto do combo?')) {
                return;
            }
            const index = this.comboProdutos.findIndex(c => c.produto_id == id);
            this.comboProdutos.splice(index, 1);
            this.comboProdutos.forEach((c, index) => (c.ordem = index + 1));
        },

        saveOrder(event) {
            const movedItem = this.comboProdutos.splice(event.oldIndex, 1)[0];
            this.comboProdutos.splice(event.newIndex, 0, movedItem);

            this.comboProdutos.forEach((c, index) => (c.ordem = index + 1));
        },

        newGrupo() {
            this.formGrupo = { ativo: true, status: 'ativo' };
            this.grupoVisible = true;
        },

        onCadastroGrupo(grupo = null) {
            this.grupoVisible = false;
            this.fillGrupos(grupo);
        },

        closeGrupo() {
            this.grupoVisible = false;
        },

        fillGrupos(grupo = null) {
            this.grupos = [];
            this.$http.get('grupos').then(resp => {
                this.grupos = resp.data.data;
                grupo && (this.form.grupo_id = grupo);
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },
        
        toogleSegundoHorario(diaSemana) {
            const programacao = this.form.disponibilidade.programacao.find(f => f.diaSemana == diaSemana);
            programacao.segundoHorarioAtivo = !programacao.segundoHorarioAtivo;
            this.$forceUpdate();
        },
    },

    directives: {
        sortableDataTable: {
            bind (el, binding, vnode) {
                const options = {
                    animation: 150,
                    onUpdate: function (event) {
                        vnode.child.$emit('sorted', event)
                    }
                }
                Sortable.create(el.getElementsByTagName('tbody')[0], options)
            },
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.v-btn--active {
    background-color: var(--bg-color) !important;
    color: var(--color) !important;
}
</style>
